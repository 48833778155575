import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/images/icon/alert.svg';
import _imports_1 from '@/assets/images/icon/success.svg';
import _imports_2 from '@/assets/images/icon/close-see-through.svg';
var _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: "",
  srcset: ""
};
var _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: "",
  srcset: ""
};
var _hoisted_3 = {
  key: 2,
  src: _imports_2,
  alt: "",
  srcset: ""
};
import { ref, reactive, onMounted, nextTick } from "vue";
export default {
  __name: 'CommonStatusIcon',
  props: {
    type: String // alert、success、close

  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return __props.type == 'alert' ? (_openBlock(), _createElementBlock("img", _hoisted_1)) : __props.type == 'success' ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : __props.type == 'close' ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true);
    };
  }
};